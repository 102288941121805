import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import WorkoutCard from "../../WorkoutCard/WorkoutCard"
import { Box, Grid, GridSpacing, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import { WorkoutVideo } from "../../types"
import { Dispatch, RootState } from "../../../../redux/store"
import { workoutSelected } from "../../browseSlice"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"
import { motion } from "framer-motion"
import { isParnterClientUser } from "../../../../utils"
import { checkHotelUsersLastLogin, logout } from "../../../login/loginSlice"
import { Status } from "../../../../redux/types"
import { UNAUTHENTICATED } from "../../../login/types"
import { PARTNER_CLIENT_DATA } from "../../../../env"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(4, 0, 0, 12),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 4, 0, 4)
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      margin: theme.spacing(0, 1),
      padding: theme.spacing(2, 0, 5, 0)
    }
  },
  grid: {
    display: "flex",
    alignContent: "flex-start",
    width: "99%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      flexWrap: "wrap",
      alignContent: "center"
    }
  },
  emptyText: {
    color: "#000",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 4)
    }
  }
}))

const itemVariants = {
  hidden: {
    y: 50, opacity: 0,
    transition: {
      y: { stiffness: 1000 }
    }
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: .2
    }
  }
}

const containerVariants = {
  visible: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 }
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
}


export default function WorkoutsGrid() {
  const classes = useStyles()
  const filteredWorkouts = useSelector((state: RootState) => state.browse.filteredWorkouts)
  const filterStatus = useSelector((state: RootState) => state.browse.filterStatus)
  const [initialState, setInitialState] = useState<"visible" | "hidden">("visible")
  const dispatch = useDispatch()
  const theme = useTheme()

  const profileResource = useSelector((state: RootState) => state.login)

  function workoutClicked(workout: WorkoutVideo) {
    if (isParnterClientUser()) {
      dispatch(checkHotelUsersLastLogin(PARTNER_CLIENT_DATA))
    }
    dispatch(workoutSelected({ workout, type: "on-demand" }))
  }


  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"))
  const isSmallerThanMedium = useMediaQuery(theme.breakpoints.down("sm"))
  const spacing: GridSpacing = isMediumScreen ? 3 : (isSmallerThanMedium ? 2 : 5)
  const maxItemAnimate = isMediumScreen ? 7 : (isSmallerThanMedium ? 5 : 15)
  const isMbsHotelUser = isParnterClientUser()

  useEffect(() => {
    if (filterStatus === "filtering")
      setInitialState("hidden")
  }, [filterStatus])

  useEffect(() => {
    if (isMbsHotelUser && profileResource.status == Status.ERROR && profileResource.error?.code === UNAUTHENTICATED) {
      dispatch(logout())
    }
  }, [profileResource.status])


  // add an empty dummy box to prevent jumping on mobile
  if (filterStatus === "filtering") {
    return <Box height={"1000px"} width={"1px"} />
  }

  function workoutCardHolder(workout: WorkoutVideo) {
    return <WorkoutCard
      lightMode={true}
      workoutVideo={workout}
      onClick={() => workoutClicked(workout)}
    />
  }

  return (
    <motion.div
      className={classes.root} variants={containerVariants} initial={initialState} animate="visible">
      {filteredWorkouts.length > 0 && <Grid container={true} spacing={spacing} className={classes.grid}>
        {filteredWorkouts.map((workout, index) => (
          <Grid xs={6} sm={6} md={6} lg={4} xl={4} item={true} key={workout.id}>
            <Box mb={{ xs: 1.5, sm: 0, md: 3, lg: 2, xl: 3 }} mr={{ md: 2, lg: 1, xl: 3 }}>
              {index < maxItemAnimate && <motion.div key={workout.id} variants={itemVariants}>
                {workoutCardHolder(workout)}
              </motion.div>}
              {index >= maxItemAnimate && workoutCardHolder(workout)}
            </Box>
          </Grid>
        ))}
      </Grid>}
      {filteredWorkouts.length == 0 &&
        <motion.div key={"no_class"} variants={itemVariants}>
          <Typography className={classes.emptyText} variant={"body1"}>Sorry, we don't have any classes yet that match your
            search. Please try looking for something else :)</Typography>
        </motion.div>}
    </motion.div>
  )
}
